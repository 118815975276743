import * as React from 'react'

import Layout from '../layouts/layout'
import sty from '../layouts/styles'
import { ContentBox } from 'src/components/Main/ContentBox'
import useImage from 'src/hooks/useImage'


const ContentList = (props) => <ul {...props} />

const ContentListItem = (props) => <li><sty.Text variant='list' {...props} /></li>


const ProductPage = () => {

  const { productEps, productStone, productPaper } = useImage()

  return (
    <Layout seo={{ title: 'Produkte' }}>
      <ContentBox
        src={false}
        title='Produkte'
        subtitle='Qualität im Vordergrund'
        textAs='div'
        text={<>
          Isolierendes Flockenmaterial wird von zahlreichen Herstellern angeboten.
          In Form, Grösse, ihrem Schmelz- und Fliessverhalten etc. gibt es aber teils grosse Unterschiede.
          Gerne beraten wir Sie in der Auswahl.
          <br/><br/>
          Nachfolgend finden Sie eine kleine <strong>Faustregel</strong> zum Material.
          Für detaillierte Informationen, stehen wir Ihnen gerne persönlich bei.
        </>}
        button={false}
      />
      <ContentBox
        src={productStone}
        title='Steinwolle'
        subtitle='Für hohe Brandanforderungen'
        textAs='div'
        text={<>
          <ContentList>
            <ContentListItem>Brandverhalten der Klasse <strong>A1/RF1</strong> (nicht-entflammbar)</ContentListItem>
            <ContentListItem>Schmelzpunkt: ca. 1000°C</ContentListItem>
            <ContentListItem>Verdichtung: 70 - 110 kg/m<sup>3</sup></ContentListItem>
          </ContentList>
        </>}
        button={false}
      />
      <ContentBox
        inverse
        src={productPaper}
        title='Papier / Zellulose'
        subtitle='Die ökologische Alternative'
        textAs='div'
        text={<>
          <ContentList>
            <ContentListItem>Brandverhalten der Klasse <strong>A2/RF2</strong> (schwer-entflammbar)</ContentListItem>
            <ContentListItem>Nicht schmelzbar</ContentListItem>
            <ContentListItem>Verdichtung: 35 - 55 kg/m<sup>3</sup></ContentListItem>
            <ContentListItem>Aus nachwachsenden Rohstoffen</ContentListItem>
          </ContentList>
        </>}
        button={false}
      />
      <ContentBox
        src={productEps}
        title='EPS / Styropor'
        subtitle='Für Spezialanwendungen'
        textAs='div'
        text={<>
          <ContentList>
            <ContentListItem>Brandverhalten der Klasse <strong>B1/RF3</strong> (schwer-entflammbar)</ContentListItem>
            <ContentListItem>Verdichtung: 20 kg/m<sup>3</sup></ContentListItem>
            <ContentListItem>Besonders geeignet bei Feuchtigkeit</ContentListItem>
          </ContentList>
        </>}
        button={false}
      />
    </Layout>
  )
}

export default ProductPage
